import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage as Img } from "gatsby-plugin-image"
import Overlay from '../styled/overlay'

const Magazine = () => {
  const data = useStaticQuery(graphql`{
    magazine: file(relativePath: {eq: "images/vm2023-2/home.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH,
          aspectRatio: 1.25,
          transformOptions: {cropFocus: NORTH}
        )
      }
    }
    story: allMdx(
      sort: {fields: fields___date, order: DESC}
      filter: {fields: {category: {eq: "stories"}}}
      limit: 1
    ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              title
              author
            }
          }
        }
      }
    }
  `)
  const story = data.story.edges[0].node
  return (
    <Link to="/verhalen/2023-2" className="relative block h-full rounded shadow overflow-hidden">
      <Img className="block h-full" image={data.magazine.childImageSharp.gatsbyImageData} alt="Vonk Magazine 2023 - 2" />
      <Overlay>
        <p className="ml-2 mb-2 text-lg xl:text-xl">
          Lees gratis de verhalen van Vonk Magazine
        </p>
        <p className="ml-2 text-lg xl:text-xl">Het nieuwste verhaal:</p>
        <p className="ml-2 mb-2 text-2xl xl:text-3xl">{story.frontmatter.title} van {story.frontmatter.author}</p>
      </Overlay>
    </Link>
  );
}

export default Magazine;
