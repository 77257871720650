import React from "react"
import { css } from "@emotion/react"

const Overlay = ({children}) =>
  <div
    css={css`background: linear-gradient(to bottom, rgba(0, 0, 0, 0), 60%, rgba(0, 0, 0, 0.4));`}
    className="absolute inset-0"
  >
    <div
      css={css`text-shadow: 0 2px 4px rgba(0,0,0,0.70);`}
      className="absolute bottom-0 p-2 text-white text-xl"
    >
      {children}
    </div>
  </div>

export default Overlay
