import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Magazine from "./index/magazine"
import Article from "./index/article"

const Highlights = () => {
  const data = useStaticQuery(graphql`{
    recentPosts: allMdx(
      limit: 4
      sort: {fields: fields___date, order: DESC}
      filter: {fields: {category: {eq: "posts"}}}
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            subtitle
            tagline
            image {
              childImageSharp {
                gatsbyImageData(
                  aspectRatio: 1.25,
                  transformOptions: {cropFocus: CENTER}
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
      }
    }
  }
  `)
  return <div className="px-4 mt-4 grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 lg:grid-rows-2 gap-4">
    <div className="col-span-2 row-span-2 md:row-span-2 lg:row-span-2"><Magazine /></div>
    {data.recentPosts.edges.map((article, index) => {
      return (
        <div key={index} className="col-span-1 sm:col-span-1 row-span-1"><Article article={article.node} /></div>
      )
    })}
  </div>
}

export default Highlights
