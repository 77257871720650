import React from "react"
import { Link } from "gatsby"
import { GatsbyImage as Img } from "gatsby-plugin-image"
import Overlay from '../styled/overlay'

const Article = ({article}) => (
  <Link to={article.fields.slug} className="relative block h-full leading-3 rounded shadow overflow-hidden">
    <div className="md:absolute md:inset-0 lg:relative lg:block overflow-hidden"><Img image={article.frontmatter.image.childImageSharp.gatsbyImageData} alt={article.frontmatter.title} /></div>
    <Overlay>
      <p className="font-sans leading-tight text-lg lg:text-2xl">{article.frontmatter.title}{article.frontmatter.tagline && `: ${article.frontmatter.tagline}`}</p>
    </Overlay>
  </Link>
)

export default Article;
