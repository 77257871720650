import React from "react"
import { Link } from "gatsby"
import { GatsbyImage as Img } from "gatsby-plugin-image"
import { formatDate } from "../../utils/date";
import Tag from "./Tag";

const WideImage = ({node}) => <Img image={node.frontmatter.wideImage.childImageSharp.gatsbyImageData} alt={node.frontmatter.title}/>

const Image = ({node}) => {
  const imageClass = "float-left w-1/3 mr-4 rounded overflow-hidden"
  return (
    <div className={imageClass}>
      <Img image={node.frontmatter.image.childImageSharp.gatsbyImageData} alt={node.frontmatter.title}/>
    </div>
  );
}

export default ({node}) => {
  const wide = node.frontmatter.list_layout === `wide`;
  return <Link to={node.fields.slug}
    className="shadow-xl bg-white rounded overflow-hidden flex flex-col"
  >
    {wide && <WideImage node={node} />}
    <div className="p-4 flex-1 flex flex-col">
      <div className="flex-1">
        {!wide && <Image node={node} />}
        <h2 className="tracking-tight font-bold text-2xl text-red-700 font-sans">{node.frontmatter.title}</h2>
        {node.frontmatter.author && <p className="tracking-tight text-xl text-gray-700 font-semibold">{node.frontmatter.author}</p>}
        <div className="flex-1">
          <p className="text-gray-800">{node.frontmatter.summary || node.excerpt}</p>
        </div>
      </div>
      <div className="flex items-center mt-2">
        <p className="font-sans text-sm text-gray-500 mr-6 leading-7">{formatDate(node.fields.date)}</p>
        <Tag category={node.frontmatter.category || node.fields.category} />
      </div>
    </div>

  </Link>
}