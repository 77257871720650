import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage as Img } from "gatsby-plugin-image"
import { css } from "@emotion/react"

import { Heading } from "../title"
import Ratio from "../ratio"
import { Wide } from "../layout"

const Reviews = () => {
  const data = useStaticQuery(graphql`{
  recentReviews: allMdx(
    limit: 6
    sort: {fields: fields___date, order: DESC}
    filter: {fields: {category: {eq: "reviews"}}}
  ) {
    edges {
      node {
        id
        fields {
          slug
        }
        frontmatter {
          title
          cover {
            childImageSharp {
              gatsbyImageData(
                width: 400
                height: 600
                transformOptions: {cropFocus: CENTER}
                layout: CONSTRAINED
              )
            }
          }
        }
      }
    }
  }
}
`)
  return (
    <Wide>
      <div className="mx-4">
        <Heading>Recensies</Heading>
        <div className="grid gap-4 grid-cols-2 sm:grid-cols-6">
          {data.recentReviews.edges.map(({ node }, index) => (
            <div className="relative" key={index}>
                <Ratio ratio={1.5}>
                  <Link to={node.fields.slug}
                    className="inset-0 absolute overflow-hidden rounded shadow"
                  >
                    <Img css={css`height: 100%;`} image={node.frontmatter.cover.childImageSharp.gatsbyImageData} alt={node.frontmatter.title}/>
                  </Link>
                </Ratio>
              </div>
          ))}
        {/* <div className="col-span-3 sm:col-span-2 md:col-span-1">
          <Ratio ratio={1.5}>
            <Link to="/boeken" className="rounded-xl flex items-center absolute inset-0 text-white text-xl font-sans" css={css`
              background-color: #9B2C2C;
            `}>
              <p className="flex-1 p-4" css={css`text-shadow: 0 1px 0 black; z-index: 20;`}>Nieuwe en aankomende boeken</p>
            </Link>
          </Ratio>
        </div> */}
        </div>
      </div>
    </Wide>
  );
}


export default Reviews
