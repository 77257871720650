import React from "react"
import { graphql, Link } from "gatsby"

import Layout, { Wide } from "../components/layout"
import Highlights from "./highlights"
import Reviews from "./index/reviews"
// import Banner from "./banners/werelden-ontwaken"
import Post from "../components/index/post"
import SEO from "../components/seo"

export const query = graphql`query ($skip: Int!, $limit: Int!) {
  posts: allMdx(
    sort: {fields: fields___date, order: DESC}
    skip: $skip
    limit: $limit
    filter: {fields: {category: {in: ["stories", "posts", "reviews"]}}}
  ) {
    edges {
      node {
        id
        fields {
          date
          slug
          category
        }
        frontmatter {
          title
          author
          list_layout
          summary
          category
          image {
            childImageSharp {
              gatsbyImageData(
                width: 400
                height: 600
                transformOptions: {cropFocus: CENTER}
                layout: CONSTRAINED
              )
            }
          }
          cover {
            childImageSharp {
              gatsbyImageData(
                width: 400
                height: 600
                transformOptions: {cropFocus: CENTER}
                layout: CONSTRAINED
              )
            }
          }
          wideImage: image {
            childImageSharp {
              gatsbyImageData(
                width: 648
                height: 365
                transformOptions: {cropFocus: CENTER}
                layout: CONSTRAINED
              )
            }
          }
        }
        excerpt(pruneLength: 250)
      }
    }
  }
}
`

const Pagination = ({currentPage, numPages}) => {
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? "/" : `/page/${currentPage - 1}`
  const nextPage = `/page/${currentPage + 1}`

  return (
    <div className="font-sans mx-2 text-xl">
      {!isFirst && (
        <Link to={prevPage} rel="prev">
          Vorige
        </Link>
      )}
      {!isLast && (
        <Link className="float-right" to={nextPage} rel="next">
          Volgende
        </Link>
      )}
    </div>
  )
}

const IndexPage = ({data, pageContext, location}) => (
  <Layout>
    <SEO title="Vonk – Over Nederlandstalige fantasy" path={location.pathname} />
    {pageContext.currentPage === 1 && (
      <>
        <Highlights />
        {/* <Banner /> */}
        <Reviews />
      </>
    )}
    <Wide>
      <div className="mx-4 grid-l">
        {data.posts.edges.map(({ node }) => <Post node={node} key={node.id} />)}
      </div>
    </Wide>
    <Wide>
      <Pagination currentPage={pageContext.currentPage} numPages={pageContext.numPages} />
    </Wide>
  </Layout>
)

export default IndexPage
