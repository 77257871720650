import React from "react";

const categories = {
  stories: "Verhaal",
  reviews: "Recensie",
  interview: "Interview"
}

const Tag = ({category}) => {
  return <>{categories[category] && <span className="rounded-full px-4 inline-block text-red-50 bg-red-600">{categories[category]}</span>}</>
}

export default Tag;